<template>
    <b-card>
        <b-row>
            <b-col cols="9">
                <b-button class="m-1 " v-ripple.400="'#1396EB'" variant="primary" v-b-modal.modal-primaryadd>
                    Добавление
                </b-button>
            </b-col>
            <b-col cols="2">
                <b-form-select class="m-1" v-model="defaultLang" :options="langs" text-field="name" value-field="code"
                    @change="getItems" />
            </b-col>
        </b-row>
        <b-modal @ok="add" @hidden="resetModal" id="modal-primaryadd" ok-only ok-title="Добавить"
            modal-class="modal-primary" centered title="Добавление">
            <b-row>
                <b-col cols="12">
                    <b-form-group>
                        <label for="input-file" class="text-nowrap">Одно изображение:</label>
                        <b-form-file v-model="room.file" id="input-file" placeholder="Добавьте изображение..."
                            drop-placeholder="Drop file here..." />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label for="input-file-multiple" class="text-nowrap">Изображение:</label>
                        <b-form-file multiple v-model="room.slaider" id="input-file-multiple"
                            placeholder="Добавьте несколько изображений..." drop-placeholder="Drop file here..." />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Название" label-for="Title">
                        <b-form-input v-model="room.title" id="Title" placeholder="Название" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label for="textarea-default" class="text-nowrap">Описание</label>
                        <b-form-textarea v-model="room.description" id="textarea-default"
                            placeholder="Добавьте описание" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Цена" label-for="price">
                        <b-form-input v-model="room.price" type="number" id="price" placeholder="Цена" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Язык" label-for="langs">
                        <b-form-select v-model="room.lang" :options="langs" text-field="name" value-field="id" />
                    </b-form-group>
                </b-col>
                <b-col md="6">
          <b-form-group>
            <label for="Quantity-default">Категории</label>
            <v-select
              v-model="room.advantages"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="title"
              :options="advantages"
              :reduce="advantage => advantage.id"
            />
          </b-form-group>
        </b-col>
            </b-row>
        </b-modal>
        <b-table :fields="tableColumns" responsive="sm" :items="rooms">
            <template #cell(img)="data">
                <b-avatar v-if="data.item.img" class="mr-1" :src="data.item.img" />
            </template>
            <template #cell(actions)="data">
                <div class="text-nowrap">
                    <b-button class="btn-icon mr-1" variant="gradient-primary" id="gradient-primaryy"
                        @click="edit(data.item)" v-b-modal.modal-primaryedit>
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button class="btn-icon" variant="gradient-danger" @click="confirmDelete(data.item)">
                        <feather-icon icon="TrashIcon" />
                    </b-button>
                </div>
            </template>
        </b-table>
        <b-modal @hidden="resetModal" @ok="handleEdit" id="modal-primaryedit" ok-only ok-title="Редактировать"
            modal-class="modal-primary" centered title="Редактирование">
            <b-row>
                <b-col cols="12">
                    <b-form-group>
                        <label for="input-file" class="text-nowrap">Одно изображение:</label>
                        <b-form-file v-model="room.file" id="input-file" placeholder="Добавьте изображение..."
                            drop-placeholder="Drop file here..." />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label for="input-file-multiple" class="text-nowrap">Изображение:</label>
                        <b-form-file v-model="room.slaider" id="input-file-multiple"
                            placeholder="Добавьте несколько изображений..." multiple
                            drop-placeholder="Drop file here..." />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Название" label-for="Title">
                        <b-form-input v-model="room.title" id="Title" placeholder="Название" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label for="textarea-default" class="text-nowrap">Описание</label>
                        <b-form-textarea v-model="room.description" id="textarea-default"
                            placeholder="Добавьте описание" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Цена" label-for="price">
                        <b-form-input v-model="room.price" type="number" id="price" placeholder="Цена" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Язык" label-for="langs">
                        <b-form-select v-model="room.lang" :options="langs" text-field="name" value-field="id" />
                    </b-form-group>
                </b-col>
                <b-col md="6">
          <b-form-group>
            <label for="Quantity-default">Категории</label>
            <v-select
              v-model="room.advantages"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="title"
              :options="advantages"
              :reduce="advantage => advantage.id"
            />
          </b-form-group>
        </b-col>
            </b-row>
        </b-modal>
    </b-card>
</template>
  
<script>
import vSelect from 'vue-select'
import { BFormSelect, BAvatar, BFormFile, BFormTextarea, BFormInput, BRow, BCol, BFormGroup, BModal, VBModal, BButton, BCard, BTable } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { $themeConfig } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        vSelect,
        BFormSelect,
        BAvatar,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BFormInput,
        BRow,
        BCol,
        BFormGroup,
        BModal,
        VBModal,
        BCard,
        BButton,
        BTable,
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
    },
    data() {

        return {
            advantages:[],
            defaultLang: 'ru',
            rooms: [],
            langs: [],
            room: {
                title: '',
                price: "",
                description: '',
                file: [],
                slaider: [],
                lang: "",
                advantages:[]
            },
            tableColumns: [
                {
                    key: 'id',
                    label: '#',
                    sortable: true,
                },
                {
                    key: 'title',
                    label: 'Название',
                    sortable: true,
                },
                {
                    key: 'img',
                    label: 'Изображение',
                    sortable: true,
                },
                // {
                //     key: 'advantages',
                //     label: 'Категория',
                //     sortable: true,
                // },
                {
                    key: 'price',
                    label: 'Цена',
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: 'Действия',
                }]
        }
    },
    mounted() {
        this.getLangs()
        this.getRooms()
        this.getRoomCategory()
    },
    methods: {
        getItems() {
            this.getRooms()
            this.getRoomCategory()
        },
        getRoomCategory() {

const getRoomCategory = {
    method: 'get',
    url: `${$themeConfig.app.API}room-advantages`,
    headers: {
        "Accept-Language": this.defaultLang
    }
};
axios(getRoomCategory)

    .then(res => {
        this.advantages = res.data.data
    })
    .catch(er => {
        console.log(er)
    })
},
        getLangs() {
            axios.get(`${$themeConfig.app.API}langs`)
                .then(res => {
                    this.langs = res.data.data
                })
                .catch(er => {
                    console.log(er)
                })
        },
        getRooms() {
            const getRoom = {
                method: 'get',
                url: `${$themeConfig.app.API}room`,
                headers: {
                    'Accept-Language': this.defaultLang
                }
            };
            axios(getRoom)
                .then(res => {
                    this.rooms = res.data.data

                })
                .catch(er => {
                    console.log(er)
                })
        },
        async add() {
            const myFormData = new FormData()
            myFormData.append('title', this.room.title)
            myFormData.append('img', this.room.file)
            myFormData.append('price', this.room.price)
            myFormData.append('description', this.room.description)
            myFormData.append('lang_id', this.room.lang)
            myFormData.append('room_advantages', this.room.advantages)
            for (let i = 0; i < this.room.slaider.length; i += 1) {
                const file = this.room.slaider[i]
                myFormData.append(`images[${i}]`, file)
            }
            await axios({
                method: 'post',
                url: `${$themeConfig.app.API}room`,
                data: myFormData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(() => {
                    this.addStatus = true
                    this.getRooms()
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Успешно',
                            text: 'Запись успешно добавлен',
                            icon: 'CheckSquareIcon',
                            variant: 'success',
                        },
                    })
                })
                .catch(e => {
                    this.addStatus = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Ошибка',
                            text: e.message,
                            icon: 'CheckSquareIcon',
                            variant: 'danger',
                        },
                    })
                })
        },
        resetModal() {
            this.room.title = ''
            this.room.lang = ''
            this.room.id = ''
            this.room.file = ''
            this.room.description = ''
            this.room.price = ''
            this.room.slaider = ''
            this.room.advantages = ''
        },
        async handleEdit() {
            const myFormData = new FormData()
            myFormData.append('title', this.room.title)
            myFormData.append('img', this.room.file)
            myFormData.append('price', this.room.price)
            myFormData.append('description', this.room.description)
            myFormData.append('lang_id', this.room.lang)
            myFormData.append('room_advantages', this.room.advantages)
            for (let i = 0; i < this.room.slaider.length; i += 1) {
                const file = this.room.slaider[i]
                myFormData.append(`images[${i}]`, file)
            }
            myFormData.append('_method', 'PUT')
            if (this.room.id !== '') {
                await axios({
                    method: 'post',
                    url: `${$themeConfig.app.API}room/${this.room.id}`,
                    data: myFormData,
                    config: {},
                })
                    // (`https://back.khojaobigarm.tj/api/news/${this.news.id}`, myFormData)
                    .then(() => {
                        this.getRooms()
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Успешно',
                                text: 'Данные были изменены',
                                icon: 'CheckSquareIcon',
                                variant: 'success',
                            },
                        })
                    })
                    .catch(e => {
                        console.log(e)
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Ошибка',
                                text: e.message,
                                icon: 'CheckSquareIcon',
                                variant: 'danger',
                            },
                        })
                    })
            }
        },
        edit(data) {
            this.room.title = data.title
            this.room.lang = data.lang.id
            this.room.id = data.id
            this.room.file = data.img
            this.room.price = data.price
            this.room.description = data.description
            this.room.slaider = data.images
            this.room.advantages = data.advantages.map(item => item.id)
            console.log(this.room.advantages)
        },
        confirmDelete(data) {
            this.$bvModal
                .msgBoxConfirm('После удаления данные восстановить нельзя будет!', {
                    title: 'Вы уверены что хотите удалить?',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отменить',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        axios.delete(`${$themeConfig.app.API}room/${data.id}`)
                            .then(() => {
                                this.getRooms()
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Успешно',
                                        text: 'Данные были удалены',
                                        icon: 'CheckSquareIcon',
                                        variant: 'success',
                                    },
                                })
                            })
                            .catch(e => {
                                console.log(e)
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Ошибка',
                                        text: e.message,
                                        icon: 'CheckSquareIcon',
                                        variant: 'dander',
                                    },
                                })
                            })
                    }
                })
        },
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>